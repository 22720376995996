// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html
import firebase from 'firebase/app'
import 'firebase/firestore'


// Initialize Firebase
// const config = {
//   apiKey: 'AIzaSyA1mTw-G1igFwVVUqTU6kKELYGOe1xJe3U',
//   authDomain: 'nudg-dashboard.firebaseapp.com',
//   databaseURL: 'https://nudg-dashboard.firebaseio.com',
//   projectId: 'nudg-dashboard',
//   storageBucket: 'nudg-dashboard.appspot.com',
//   messagingSenderId: '717287763895'
// }

// firebase.initializeApp(db)


export const db = firebase
  .initializeApp({ 
    apiKey: 'AIzaSyA1mTw-G1igFwVVUqTU6kKELYGOe1xJe3U',
    authDomain: 'nudg-dashboard.firebaseapp.com',
    databaseURL: 'https://nudg-dashboard.firebaseio.com',
    projectId: 'nudg-dashboard',
    storageBucket: 'nudg-dashboard.appspot.com',
    messagingSenderId: '717287763895'
  })
  .firestore()

